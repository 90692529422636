//import { fontStyle } from '@mui/system'
import React from 'react'
import './Home.css'
import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
function Home() {
  return (
    <div className="home-container">
      <Typography fontWeight={'800'} sx={{width:'max-content',position:'absolute', opacity:{xs:'0', md:'1'}, top:'50%', left:'50%', transform:'translate(-50%, -50%)', padding:'0'}} variant="h1">D'abord vous voyez ça :)</Typography>
      <Typography fontWeight={'700'} sx={{width:'max-content', position:'absolute', opacity:{xs:'0', md:'1'}, top:'80%', right:'50%', transform:'translate(50%)'}} variant="h3">Ensuite vous voyez ça</Typography>
      <Typography fontWeight={'600'} sx={{width:'max-content', position:'absolute', opacity:{xs:'0', md:'1'}, top:'10%', left:'2%'}} variant="h5">Puis vous voyez ça</Typography>
      <Typography fontWeight={'500'} sx={{width:'max-content', position:'absolute', opacity:{xs:'0', md:'1'}, top:'10%', right:'2%'}} variant="h6">Et puis ça</Typography>
      <Box sx={{width:'max-content',  position:'absolute', top:{xs:'50%', md:'90%'}, right:{xs:'auto', md:'1%'}, left:{xs:'50%', md:"auto"}, transform:{xs:'translate(-50%, -50%)', md:'translate(0%, 0%)'},}}>
        <Link  style={{width:'max-content', color:'white', textDecoration:'none'}}  to="/contact">
          <Typography className='contact-link' fontSize={'10px'} variant='h7'>Contactez nous</Typography>
        </Link>
      </Box>
    </div>
  )
}

export default Home