import React from 'react'
import "./Contact.css"
import {PopupButton} from 'react-calendly'
import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

function ContactItem(props)
{
  return(
    <div className='contact-item-container'>
        <a className='contact-item' id={props.id} href={props.href}><h4 id="contact-title">Send us an email</h4> {props.text}</a>
    </div>
  )
}

function CalendlyWidget()
{
  return(
    <div id="calendly-button">
      <div id='popup-container'>

      <PopupButton
          className='popo'
          url="https://calendly.com/243studio/consultation-clone?hide_gdpr_banner=1"
          rootElement={document.getElementById("root")}
          text="Book consultation (FREE)"
          textColor="#ffffff"
          color="#007FFF"
          branding= "true"
          border="solid 10px black"
          styles={{
            all:"unset",
            height: '15vh', 
            borderRadius: '5px',
            borderBlock:'none',
            fontSize: '15px', 
            fontWeight: 'bold',
            width:'100%',
            color:'black', 
            backgroundColor:'white',
            border: 'solid 3px black ',
            boxShadow:'7px -6px 0px -1px #000000', 
            WebkitBoxShadow:'7px -6px 0px -1px #000000', 
          }}
          />
      </div>
    </div>
  )
}
export default function Contact() {
  return (
    <div className='gen-container' >
      <CalendlyWidget/>
        <h4 style={{margin:"0"}}>or</h4>
      <div className='contact-container'>
        
        <ContactItem
        href='mailto:info@243studio.me'
        text="info@243studio.me"
        />
      </div>
      <Typography variant='h7' style={{fontSize:'12px', fontWeight:'400', position:'absolute', top:'85%', margin:'0', padding:'0'}}>Uhmm... <Link  className="home-button" style={{color:"white", fontWeight:'800', textTransform:'uppercase', textDecoration:'none'}}to={'/'}>Leave/Quitter?</Link></Typography>
      {/**<Typography variant='h6' style={{ fontSize:'12px', fontWeight:'400', position:'absolute', top:'90%', margin:'0', padding:'0'}}>or wanna see... <Link className='portfolio'  style={{color:"white", fontWeight:'800', textTransform:'uppercase', textDecoration:'none'}}to={'/'}>portfolio?</Link></Typography>**/}
    </div>
  )
}
