import {Routes, Route} from 'react-router-dom'
import {useState, useEffect} from "react"
//import logo from './logo.svg';
import './App.css';
import Contact from './Page/Contact';
import Home from './Page/Home'
import { useLocation } from 'react-router-dom';

import NotFound from './Page/Notfound'


import Studio243 from './Page/Studio243'
import {BlogDetail, BlogList} from './Page'
import { createTheme, ThemeProvider } from '@mui/material';
import Editor from './Page/Editor/Editor';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Chakra Petch", 'sans-serif'
    ].join(','),
  },
});

function App() {


  const [showButton, setShowButton] = useState(false);
  //const [fixNav, setFixNav] = useState('fixed')
  //const [currentUrl, setCurrentUrl] = useState(window.location.href)
  const location = useLocation();

  function checkScroll(){
       if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScroll )
    
    return () => window.removeEventListener("scroll", checkScroll )
  }, []);


  useEffect(() => {
  }, [location]);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };


 // const url2 = "https://swapi.dev/api/people/1"

  
  

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Routes >
        <Route path='' element={<Home/>}/>

        <Route path="studio" element={<Studio243/>}/>
        <Route path="blog/" element = {<BlogList/>} />
        <Route path="blog/:id" element = {<BlogDetail/>} />
        <Route path="contact" element = {<Contact/>} />
        <Route path="editor" element={<Editor/>}/>
        <Route path="/*" element={<NotFound/>}/>

      </Routes>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          &#8679;
        </button>
      )}
    </div>
    </ThemeProvider>
  )
}

export default App;
